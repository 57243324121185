import { supabase } from './supabase.js';

async function loadSponsoredLogos(placement) {
   try {
       const now = new Date();
    //    console.log('Current time (UTC):', now.toISOString());

       const { data: sponsoredLogos, error } = await supabase
           .from('sponsored_logos')
           .select('*')
           .eq('status', 'active')
           .lte('start_date', now.toISOString())
           .gte('end_date', now.toISOString())
           .in('placement', [placement, 'both']);

       if (error) throw error;

    //    console.log('Supabase query result:', sponsoredLogos);

       return sponsoredLogos;
   } catch (error) {
       console.error('Error loading sponsored logos:', error);
       return [];
   }
}

function displaySponsoredLogos(logos, containerSelector, isWeeklyMotion = false) {
    const container = document.querySelector(containerSelector);
    if (!container) {
        console.error(`Container not found for selector: ${containerSelector}`);
        return;
    }

    const parentContainer = isWeeklyMotion ? 
        document.getElementById('sponsored_logos_container') : 
        container.closest('.max-w-7xl');

    if (!parentContainer) {
        console.error('Parent container not found');
        return;
    }

    const activeLogos = logos.filter(logo => logo.status === 'active');

    if (activeLogos.length > 0) {
        // console.log('Active logos data:', activeLogos);

        let logosHTML = activeLogos.map(logo => {
            let correctedUrl = logo.sponsored_logo_url?.replace('/v.ect/', '/v1/');
            return `
                <img src="${correctedUrl || ''}" 
                     alt="${logo.company_name || 'Sponsored'} Logo" 
                     class="h-6 w-auto object-contain transition-all duration-200"
                     onerror="this.style.display='none'; console.error('Error loading image:', this.src);">
            `;
        }).join('');

        container.innerHTML = logosHTML;
        parentContainer.classList.remove('hidden');
        parentContainer.style.display = 'block'; // Ensures the container is shown
    } else {
        // console.log('No active logos available, hiding container');
        parentContainer.classList.add('hidden');
        parentContainer.style.display = 'none'; // Ensures the container doesn't take up space
    }
}

export async function initSponsoredLogos(placement, containerSelector, isWeeklyMotion = false) {
    const logos = await loadSponsoredLogos(placement);

    if (logos.length === 0) {
        // console.log('No sponsored logos available, hiding section');
        const parentContainer = isWeeklyMotion ? 
            document.getElementById('sponsored_logos_container') : 
            document.querySelector(containerSelector)?.closest('.max-w-7xl');
        
        if (parentContainer) {
            parentContainer.classList.add('hidden');
        }
        return [];
    }

    // console.log('Logos data:', logos);
    displaySponsoredLogos(logos, containerSelector, isWeeklyMotion);
    return logos;
}

export { loadSponsoredLogos };